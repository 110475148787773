export enum AppRoute {
    ROOT = "/",
    START_PAGE = "/start-page",
    NOT_FOUND = "/not-found"
}

export enum BackendRoutes {
    GET_BOLETOS = "/boleto/get-boletos",
    DOWNLOAD_BOLETO = "/boleto/download-boleto",
    TEST_DATE = "/interceptor/test-value"
}
export enum BackendTest {
    TEST_DATE = "/interceptor/test-value"
}