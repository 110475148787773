import axios from "axios";
import {BoletoService} from "../service/boletoService/boletoService";
import {BackendTest} from "../models/Routers";


const url = process.env.REACT_APP_HOST;

const httpClient = axios.create({
    baseURL: url
});

httpClient.interceptors.request.use(async config=>{
    const boletoService = new BoletoService();
    if(!config.url?.includes(BackendTest.TEST_DATE) && isValid()){
        let result = await boletoService.getTestData();
        console.log(result.data.body);
    }
    return config;
});

function isValid(): boolean {
    return true
}

httpClient.interceptors.response.use(response=>{
    return response;
})

export default httpClient
